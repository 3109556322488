import { User } from "./User";
import { JsHelpers } from "./JsHelpers";
import { AppUiTools } from "./AppUiTools";

declare var $:any;

export class App {

    /**
     * Holds instances of services
     */
    public container:any = {
        app:            this,
        jQuery:         $,
    };

    constructor() {

        this.initializeCoreInstance();

        return this;
    }

    private initializeCoreInstance() {

        this.container.user         = new User(this.container);
        this.container.jsHelpers    = new JsHelpers(this.container);
        this.container.appUiTools   = new AppUiTools(this.container);

    }
}




