/// <reference path="../references/jsTools/JsBasics.js" />

export interface credentialsEmail {
    email:string,
    password:string
}

export interface credentialsFacebook {
    facebook:string
}

export class User {

    private t = {
        loginError:              'Error when loggin in'
    };

    private s = {
        loadingClass:                       'loading'
    };

    private e = {

        ajaxLogin_loading:                  <HTMLInputElement>document.querySelector('.user-ajax-login-loading'),
        ajaxLogin_email:                    <HTMLInputElement>document.getElementById('user-ajax-login-email'),
        ajaxLogin_password:                 <HTMLInputElement>document.getElementById('user-ajax-login-password'),
        ajaxLogin_button:                   <HTMLButtonElement>document.getElementById('user-ajax-login-button'),

    };

    protected o = {

        url_login:              '/user/login',

    };

    constructor(private container:any) {

        this.initializeLoginEventListener();

        return this;
    }

    protected initializeLoginEventListener() {

        let self = this;

        if(this.e.ajaxLogin_button && this.e.ajaxLogin_email && this.e.ajaxLogin_password)
            this.e.ajaxLogin_button.addEventListener('click', function(e:Event) {

                e.preventDefault();

                let credentials:credentialsEmail = {
                    email:      self.e.ajaxLogin_email.value,
                    password:   self.e.ajaxLogin_password.value,
                };

                if(self.e.ajaxLogin_loading)
                    self.e.ajaxLogin_loading.className += ' '+self.s.loadingClass;

                self.login.apply(self, [credentials]).then(() => {
                    location.reload();
                }).catch((e:any) => {

                    console.error(e);

                    if(self.e.ajaxLogin_loading)
                        self.e.ajaxLogin_loading.className = self.e.ajaxLogin_loading.className.replace(self.s.loadingClass, '');

                });

                return false;
            });
        else
            console.warn('Elements #user-ajax-login-* not found')

    }

    /**
     * @param {object} credentials
     * @return {Promise}
     */
    public login(credentials: credentialsEmail & credentialsFacebook) {

        if(isset(credentials.email, credentials.password))
            return this.login_byEmail(credentials);

        if(isset(credentials.facebook))
            return this.login_byFacebook(credentials);

        throw Error('Unknown credentials');
    };

    private login_byEmail(credentials: credentialsEmail) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.container.jQuery.ajax({

                url: self.o.url_login,
                type: 'post',
                data: {
                    credentials: credentials
                },
                complete: (data:any) => { return self.handle_loginResponse.apply(self, [resolve, reject, data]) }

            })
        });
    }

    private login_byFacebook(credentials: credentialsFacebook) {
        let self = this;
        return new Promise((resolve, reject) => {
            reject("Not implemented yet");
        })
    }

    private handle_loginResponse(resolve:Function, reject:Function, response:XMLHttpRequest) {

        if(response.status === 200 && response.responseText)
            return resolve(response);

        alert(this.t.loginError);
        console.error(response.responseText);

        reject(response);
    }
}