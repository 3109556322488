export class JsHelpers {

    constructor(private container:any) {

        this.initializeEventListeners();

    }

    private initializeEventListeners() {


    }
}