declare var $:any;

export class AppUiTools {

    private s = {

        appUp_unHideBtn_class:        '__un-hide',
        appUp_hideBtn_class:          '__hide',
        appUp_hideToggleBtn_class:    '__hideToggle',

    };

    constructor(private container:any) {

        this.initializeEventListeners();

        this.initializeTabSwitchers();

        AppUiTools.initializeJqueryPlogins();

    }

    private initializeEventListeners() {

        let self = this;
        document.body.addEventListener('click', (e:Event) => {

            let target:HTMLElement = <HTMLElement>e.target;
            if(target.className.match(self.s.appUp_hideBtn_class))
                AppUiTools.handle_hideEvent.apply(self, [target, e]);

            if(target.className.match(self.s.appUp_unHideBtn_class))
                AppUiTools.handle_unHideEvent.apply(self, [target, e]);

            if(target.className.match(self.s.appUp_unHideBtn_class))
                AppUiTools.handle_hideToggleEvent.apply(self, [target, e]);

        })

    }

    private static handle_hideEvent(element:HTMLElement, e:Event) {

        let el:HTMLElement;
        e.preventDefault();

        if(element.dataset.hideSelector && (el = document.querySelector(element.dataset.hideSelector)))
            el.hidden = true;

    }

    private static handle_unHideEvent(element:HTMLElement, e:Event) {

        let el:HTMLElement;
        e.preventDefault();
        if(element.dataset.unHideSelector && (el = document.querySelector(element.dataset.unHideSelector)))
            el.hidden = false;

    }

    private static handle_hideToggleEvent(element:HTMLElement, e:Event) {

        let el:HTMLElement;
        e.preventDefault();
        if(element.dataset.hideToggleSelector && (el = document.querySelector(element.dataset.hideToggleSelector)))
            el.hidden = !el.hidden;

    }

    private initializeTabSwitchers() {
        $(function() {
            let tabLinks = $('.tab-switcher .tab-link'), tabContainers = '';

            tabLinks.each(function() {
                tabContainers += (tabContainers===''?'':', ')+this.dataset.tabSelector;
            });

            let containers = $(tabContainers);
            tabLinks.on('click', function() {

                containers.removeClass('active');
                tabLinks.removeClass('active');
                $(this).addClass('active');
                $(this.dataset.tabSelector).addClass('active');

            });
        })
    }

    private static initializeJqueryPlogins() {

        AppUiTools.initializeSelect2();

    }

    private static initializeSelect2() {
        $('.select2').select2({
            minimumResultsForSearch: 5,
            tokenSeparators: [',', ' ', "\t"],
            width: '100%'
        });
    }
}